import { Injectable } from '@angular/core'
import { ApiRequestService } from './api-request.service'
import { LogService } from './log.service'
import { AssetService } from './asset.service'
import { FilterService } from './filter.service'
import { HttpErrorResponse } from '@angular/common/http'
import { IAsset } from '../interfaces/asset.interface'
import { Observable, of, take } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSelectChange } from '@angular/material/select'
import { MockService } from './mock.service'
import { FetchService } from './fetch.service'
import { EnvironmentService } from './environment.service'
import { RangeFilterComponent } from 'src/app/components/filters/range/range-filter.component'
import { ZipCodeFilterComponent } from 'src/app/components/filters/zip-code/zip-code-filter.component'
import { IJsonLogic } from '../interfaces/json-logic.interface'
import { FilterId } from '../interfaces/filter.interface'
import { IPagination } from '../interfaces/api-request.interface'
import { ISearchResults } from '../interfaces/search.interface'
import { EventService } from './event.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DrilldownFilterComponent } from 'src/app/components/filters/drilldown/drilldown-filter.component'

@Injectable()
export class SearchService {

  pagination: IPagination = {
    field: 'equipment_number',
    direction: 'asc',
    index: 0,
    size: 15,
  }
  sort!: string | null
  totalResults = 0

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _mockService: MockService,
    private _fetchService: FetchService,
    private _apiRequestService: ApiRequestService,
    private _envService: EnvironmentService,
    private _logger: LogService,
    private _assetService: AssetService,
    private _filterService: FilterService,
    private _eventService: EventService,
  ){
    this._eventService.filterChanged$.pipe(
      takeUntilDestroyed(),
    ).subscribe((data) => {
      this.handleFilterUpdated()
    })
  }

  loadAssets(): Promise<IAsset[]> {
    return new Promise((resolve, reject) => {
      this._assetService
        .getAssets({
          pagination: this.pagination,
          filters: this._filterService.build(({ is, and }) => {
            const allFilters = [
              is.listed,
            ]
            const selectedFilters = this.buildFilters()
            if (selectedFilters.length > 0) {
              allFilters.push(and(...selectedFilters))
            }
            return and(
              ...allFilters,
            )
          }),
          queryParams: this.buildQueryParams()
        })
        .pipe(take(1))
        .subscribe(response => {
          if (response instanceof HttpErrorResponse) {
            this._logger.error('Unable to load assets for featured carousel.  response: ', response)
            return reject('Unable to load assets for featured carousel')
          }
          this.pagination = response.pagination
          this.totalResults = response.pagination.total_items
          resolve(response.data)
        })
    })
  }

  buildFilters(): IJsonLogic[] {
    const filters: IJsonLogic[] = []
    if (this._filterService.hasFilter(FilterId.CatSubcat)) {
      filters.push(DrilldownFilterComponent.buildFilters(
        this._filterService,
        this._filterService.getFilter(FilterId.CatSubcat).state,
        ['category', 'sub_category'],
      ))
    }
    if (this._filterService.hasFilter(FilterId.MakeModel)) {
      filters.push(DrilldownFilterComponent.buildFilters(
        this._filterService,
        this._filterService.getFilter(FilterId.MakeModel).state,
        ['make', 'model'],
      ))
    }
    if (this._filterService.hasFilter(FilterId.Year)) {
      filters.push(DrilldownFilterComponent.buildFilters(
        this._filterService,
        this._filterService.getFilter(FilterId.Year).state,
        ['year'],
      ))
    }
    if (this._filterService.hasFilter(FilterId.Location)) {
      filters.push(DrilldownFilterComponent.buildFilters(
        this._filterService,
        this._filterService.getFilter(FilterId.Location).state,
        ['branch_country', 'branch_state', 'branch_city'],
      ))
    }
    if (this._filterService.hasFilter(FilterId.PriceRange)) {
      filters.push(RangeFilterComponent.buildFilter(
        this._filterService,
        this._filterService.getFilter(FilterId.PriceRange).state,
        'list_price'
      ))
    }
    if (this._filterService.hasFilter(FilterId.MilesRange) && this._filterService.hasFilter(FilterId.HoursRange)) {
      filters.push(
        this._filterService.build(({ or }) => {
          return or(
            RangeFilterComponent.buildFilter(
              this._filterService,
              this._filterService.getFilter(FilterId.MilesRange).state,
              'meter_miles_precise'
            ),
            RangeFilterComponent.buildFilter(
              this._filterService,
              this._filterService.getFilter(FilterId.HoursRange).state,
              'meter_hours'
            ),
          )
        })
      )
    } else {
      if (this._filterService.hasFilter(FilterId.MilesRange)) {
        filters.push(RangeFilterComponent.buildFilter(
          this._filterService,
          this._filterService.getFilter(FilterId.MilesRange).state,
          'meter_miles_precise'
        ))
      }
      if (this._filterService.hasFilter(FilterId.HoursRange)) {
        filters.push(RangeFilterComponent.buildFilter(
          this._filterService,
          this._filterService.getFilter(FilterId.HoursRange).state,
          'meter_hours'
        ))
      }
    }
    if (this._filterService.hasFilter(FilterId.ZipCode)) {
      filters.push(ZipCodeFilterComponent.buildFilter(
        this._filterService,
        this._filterService.getFilter(FilterId.ZipCode).state,
        'distance'
      ))
    }
    return filters
  }

  buildQueryParams(): {[key: string]: string} {
    let queryParams = {}
    if (this._filterService.hasFilter(FilterId.ZipCode)) {
      queryParams = {
        ...ZipCodeFilterComponent.buildQueryParams(this._filterService.getFilter(FilterId.ZipCode).state)
      }
    }
    return queryParams
  }

  handlePageEvent(event: any): void {
    this.pagination.index = event.pageIndex
    this._router.navigate([], {
      relativeTo: this._route,
      queryParamsHandling: 'merge',
      queryParams: { page: this.pagination.index + 1 }
    })
  }

  handleSortChange(event: MatSelectChange): void {
    this.sort = event.value
    if (!this.sort) {
      this._router.navigate([], {
        relativeTo: this._route,
        queryParamsHandling: 'merge',
        queryParams: { sort: null, page: null }
      })
    } else {
      this._router.navigate([], {
        relativeTo: this._route,
        queryParamsHandling: 'merge',
        queryParams: { sort: this.sort, page: null }
      })
    }
  }

  handleFilterUpdated(): void {
    if (this._filterService.hasAnyFilters()) {
      this._router.navigate([], {
        relativeTo: this._route,
        queryParamsHandling: 'merge',
        queryParams: {
          filters: this._filterService.encodeFilters(this._filterService.getFiltersWithState()),
          page: 1,
        }
      })
    } else {
      const sortParam = !this.sort ? {} : { sort: this.sort }
      this._router.navigate([], {
        queryParams: {
          ...sortParam,
        }
      })
    }
  }

  getSearchResults(term: string, limit: number): Observable<ISearchResults> {
    this._logger.debug('SearchService.getSearchResults() term: ', term, ' limit: ', limit)
    if (this._envService.get().useMock) {
      return of(this._mockService.getSearchResults(term))
    }
    return this._fetchService.getRequest<ISearchResults>(`${this._apiRequestService.buildEndpoint(`search?term=${encodeURIComponent(term)}&limit=${limit}`)}`) as Observable<ISearchResults>
  }
}
